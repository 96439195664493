<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field v-model="store.name" name="name" label="店铺名称" placeholder="请输入" />
      <van-field name="uploader" label="店铺LOGO">
        <template #input>
          <van-uploader
            v-model="fileList"
            :max-count="1"
            :max-size="10 * 1024 * 1024"
            :after-read="afterRead"
          />
        </template>
      </van-field>
      <van-field v-model="store.tel" type="tel" name="tel" label="联系电话" placeholder="请输入" />
      <van-field v-model="store.address" name="address" label="店铺地址" placeholder="请输入" />
      <van-field v-model="store.allianceIds" name="allianceIds" label="联盟店ID" placeholder="请输入" />
      <van-field v-model="store.chainIds" name="chainIds" label="联锁店ID" placeholder="请输入" />
      <van-field v-model="store.shareEffectiveDays" name="shareEffectiveDays" label="分享有效期" type="digit" placeholder="超过时限自动解除" />
      <van-field v-model="store.customerBindingEffectiveDays" name="customerBindingEffectiveDays" label="顾客有效期" type="digit" placeholder="超过时限自动解除" />
      <van-field name="allowRefund" label="付款身份显示">
        <template #input>
          <van-switch v-model="store.isswitch" size="20" />
        </template>
      </van-field>
    </van-cell-group>
    <!-- <van-field name="onPlatform" label="加入平台">
      <template #input>
        <van-switch v-model="store.onPlatform" size="20" />
      </template>
    </van-field>
    <van-field name="registerMember" label="会员购买">
      <template #input>
        <van-switch v-model="store.registerMember" size="20" />
      </template>
    </van-field> -->
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
    <!-- <div style="margin: 16px;">
      <van-button round block :to="{ name: 'Position'}">
        店铺位置
      </van-button>
    </div> -->
    <div style="margin: 16px;">
      <van-button round block :to="{ name: 'Diy'}">
        店铺装修
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import { upload } from '@/util/util'

export default {
  props: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      password: '',
      fileList: [],
      show: false,
      storeId: Cookies.get('storeId'),
      qiniu: {},
      store: {}
    })
    const onSubmit = (values) => {
      if (values.uploader.length === 0) {
        Toast('请上传一个LOGO')
        return false
      }
      values.logo = values.uploader[0].url
      values.isswitch = state.store.isswitch ? 1 : 0
      delete values.uploader
      Toast.loading({ forbidClick: true, duration: 0 })
      post('/store.update', {
        ...state.store,
        ...values,
        allianceIds: values.allianceIds !== '' ? values.allianceIds.split('-') : [],
        chainIds: values.chainIds !== '' ? values.chainIds.split('-') : []
      }).then(res => {
        Toast.clear()
        if (res.code === 0) {
          Toast.success()
          init()
        } else {
          Toast(res.msg)
        }
      })
    }
    const beforeRead = (file) => {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
        Toast('图片类型支持: jpg,png,gif')
        return false
      }
      return true
    }
    const afterRead = (file) => {
      upload({
        fileBlob: file.file,
        token: state.qiniu.token,
        domain: state.qiniu.domain
      }).then(res => {
        file.url = res.img
      })
    }
    const init = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        state.store = res.data
        if (state.store.isswitch === 1) {
          state.store.isswitch = true
        } else {
          state.store.isswitch = false
        }
        state.store.allianceIds = res.data.allianceIds.join('-')
        state.store.chainIds = res.data.chainIds.join('-')
        if (res.data.logo !== '') {
          state.fileList = []
          state.fileList.push({
            url: res.data.logo,
            isImage: true
          })
        }
      })
      post('/util.getQiniuToken', {
      }).then(res => {
        state.qiniu = res.data
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      beforeRead,
      afterRead
    }
  }
}
</script>

<style scoped>
</style>
